import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './body/body.component';
import { SideNavListComponent } from './side-nav-list/side-nav-list.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { NguCarouselModule } from '@ngu/carousel';
import { FooterComponent } from './footer/footer.component';
import { CoresService } from './core.service';
import { AuthService } from '../auth/auth.service';
import { UpdateLeadModalComponent } from './footer/update-lead-modal/update-lead-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModalComponent } from './footer/menu-modal/menu-modal.component';
import { HeaderHomeComponent } from './home/header-home/header-home.component';
import { ProfileService } from '../services/profile.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  declarations: [BodyComponent, SideNavListComponent,
    HomeComponent, FooterComponent, UpdateLeadModalComponent, MenuModalComponent, HeaderHomeComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NguCarouselModule
  ],
  providers: [
    CoresService,
    AuthService,
    ProfileService
  ],
  entryComponents: [
    UpdateLeadModalComponent,
    MenuModalComponent
  ]
})
export class CoreModule { }
