<div class="block" fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="leadForm" (ngSubmit)="onSubmit()" class="form" fxLayout="column"
    fxFlex="100%"
    fxFlex.xs="100%"
    fxFlex.sm="100%"
    fxLayoutAlign="center center"
  >
  <div class="container" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="column" fxLayoutAlign="space-around center">
    <div fxLayout="column" fxLayoutAlign="center center" class="input-lead" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <span class="label">Jumlah Leads</span>
        <input formControlName="lead" class="input" type="text" />
    </div>
    <div class="input-date" fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field class="input-date">
                    <input name="date"  formControlName="date" id="date" matInput class="date--text date">
            </mat-form-field>
            <span><i class="material-icons">
              calendar_today
              </i></span>
    </div>

    <div class="button" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="row" fxLayoutAlign="space-around end">
            <span  (click)="onCancel()" fxLayout="row" fxLayoutAlign="center center" class="cancel" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">Cancel</span>
            <button type="submit" fxLayout="row" fxLayoutAlign="center center" class="submit" fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">Input</button>
    </div>
  </div>
</form>

</div>
