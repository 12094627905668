import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, pluck } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()
export class CoresService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getContentHome(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/dashboards/points/home')
        .pipe(
          map (response => response),
          pluck('data'),
          catchError(this._errorHandler)
        );
  }



  _errorHandler(error: Response) {
    return throwError(error || 'Server Error');
  }
}
