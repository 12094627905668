<div class="block" fxLayout="row" fxLayoutAlign="center center">
    <div class="container" fxLayout="column" fxLayoutAlign="space-around center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="first">
            <span (click)="onOpenDashboard()" class="icon" fxFlex="35%" fxFlex.xs="45%" fxFlex.sm="35%" fxLayout="column" fxLayoutAlign="center center">
                <img class="image" src="assets/icons/home.png" alt="">
                <span class="title">Dashboard</span>
            </span>
            <span (click)="onOpenReport()" class="icon" fxFlex="35%" fxFlex.xs="45%" fxFlex.sm="35%" fxLayout="column" fxLayoutAlign="center center">
                <img class="image" src="assets/icons/notepad.png" alt="">
                <span class="title">Laporan</span>

            </span>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="first">
            <span (click)="onOpenRapot()" class="icon" fxFlex="35%" fxFlex.xs="45%" fxFlex.sm="35%" fxLayout="column" fxLayoutAlign="center center">
                <img class="image" src="assets/icons/notebook.png" alt="">
                <span class="title">Report</span>
            </span>
            <span (click)="onLogout()" class="icon" fxFlex="35%" fxFlex.xs="45%" fxFlex.sm="35%" fxLayout="column" fxLayoutAlign="center center">
                <img class="image" src="assets/icons/exit.png" alt="">
                <span class="title">Log Out</span>

            </span>
        </div>
    </div>
</div>
      