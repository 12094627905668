<div class="container-footer" fxLayout="row" fxLayoutAlign="center end">
    <div  class="menu" fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="row" fxLayoutAlign="center start">
        <div class="block" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" 
            fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" class="left" fxLayout="row" fxLayoutAlign="start start">
                <div (click)="onOpenMenu()"  class="icon-menu" fxLayout="column" fxLayoutAlign="center center">
                        <i class="material-icons mat-icon">menu</i>                      
                </div>
            </div>
            <div fxFlex="80%" fxFlex.xs="80%" fxFlex.sm="80%" class="center" fxLayout="row" fxLayoutAlign="center center">
                <span (click)="onUpdateLead()" class="lead-button" fxLayout="row" fxLayoutAlign="center center">Update Leads</span>
            </div>
            <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" class="right" fxLayout="row" fxLayoutAlign="end start">
                <div (click)="onOpenProfile()" class="icon-menu" fxLayout="column" fxLayoutAlign="center center">
                    <i class="material-icons mat-icon">account_circle</i>                       
                </div>             
            </div>
        </div>
  
    </div>
  </div>