import { Component, OnInit } from '@angular/core';
import * as fromAll from '../../../reducers/allreducer.reducer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/reducers/auth.reducer';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {
  $appState: Observable<{title: string, loading: boolean, backUrl: string }>;
  $authState: Observable<AuthState>;

  $backUrl: Observable<string>;
  backUrl: string;
  $profileUrl: Observable<string>;
  profileUrl: string;
  constructor(
    private store: Store<fromAll.AllState>,
  ) {
    this.$appState   = this.store.select('app');
    this.$authState   = this.store.select('auth');

   }

  ngOnInit() {
    this.$profileUrl = this.$authState.pipe(map(storeAuth => storeAuth.profile ));
    this.$profileUrl.subscribe(data => {
      this.profileUrl = data;
      console.log(data);
    });
    this.$backUrl = this.$appState.pipe(map(storeApp => storeApp.backUrl ));
    this.$backUrl.subscribe(data => {
      this.backUrl = data;
    });
  }

}
