import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { CoresService } from '../../core.service';
import { ProfileService } from 'src/app/services/profile.service';

import * as moment from 'moment';


@Component({
  selector: 'app-update-lead-modal',
  templateUrl: './update-lead-modal.component.html',
  styleUrls: ['./update-lead-modal.component.scss'],

})
export class UpdateLeadModalComponent implements OnInit {
  leadForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<UpdateLeadModalComponent>,
    private coverService: CoresService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.leadForm = new FormGroup({
      lead: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
      date: new FormControl({ value: moment().format('DD MMMM YYYY'), disabled: true }, [Validators.required])
    });
  }

  onSubmit() {
    console.log('test')
    this.profileService.createLeads(this.leadForm.value)
      .subscribe(data => {
        this.dialogRef.close(true);
      });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
