<div class="spinner-container">
    <mat-spinner *ngIf="($isLoading | async)" class="spinner"></mat-spinner>
</div>

<div class="body-container" fxLayout="row" fxLayoutAlign="center center">
    <div class="body" fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>


