import { Action } from '@ngrx/store';

export const SIGNIN = 'SIGNIN';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_ROLE = 'SET_ROLE';
export const SET_PHONE = 'SET_PHONE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PROFILE = 'SET_PROFILE';






export class Signin implements Action {
  readonly type = SIGNIN;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: string) {}
}

export class SetEmail implements Action {
  readonly type = SET_EMAIL;

  constructor(public payload: string) {}
}

export class SetPhone implements Action {
  readonly type = SET_PHONE;

  constructor(public payload: string) {}
}

export class SetUser implements Action {
  readonly type = SET_USER;

  constructor(public payload: string) {}
}

export class SetRole implements Action {
  readonly type = SET_ROLE;

  constructor(public payload: string) {}
}

export class SetProfile implements Action {
  readonly type = SET_PROFILE;

  constructor(public payload: string) {}
}

export type AuthActions = Signin | Logout | SetToken | SetRole | SetUser | SetPhone | SetEmail | SetProfile;
