import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundTwoDigit'
})

export class RoundTwoDigitPipe implements PipeTransform {
  transform(value: any): any {
    if (value && Number(value) !== 0) {
      const x = Number(value).toFixed(2);
      const numberWithComma = Number(x).toLocaleString('id');
      return numberWithComma;
      } else {
        return 0;
      }
  }
}
