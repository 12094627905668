import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, pluck } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from 'src/app/interface/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
const helper = new JwtHelperService();

export interface ProfileService {
  lead: any;
  sesi: number;
}

@Injectable()
export class ProfileService {

  constructor(
    private httpClient: HttpClient
  ) { }

  editUser(userObj: User): Observable<any> {
    return this.httpClient.put(environment.apiUrl + '/users', userObj)
        .pipe(
          map (response => response),
          pluck('data'),
          catchError(this._errorHandler)
        );
  }

  getUserId() {
    const token = JSON.parse(localStorage.getItem('token'));
    const decoded = helper.decodeToken(token);
    return decoded.sub.id;
  }


  updatePhotoProfile(photoObj: any) {
    return this.httpClient.post(environment.apiUrl + '/users/upload-profile', photoObj)
        .pipe(
          map (response => response),
          pluck('data'),
          catchError(this._errorHandler)
        );
  }


  getProfilePoint(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/dashboards/points/profile')
        .pipe(
          map (response => response),
          pluck('data'),
          catchError(this._errorHandler)
        );
  }

  getLeadBudgetPointByMonth(filterObj: any): Observable<any> {
    const params = new HttpParams()
    .set('month', filterObj.month);
    return this.httpClient.get(environment.apiUrl + '/dashboards/points/user/bymonth', {params})
        .pipe(
          map (response => response),
          pluck('data'),
          catchError(this._errorHandler)
        );
  }

  getPointWeekly(filterObj: any): Observable<any> {
    const params = new HttpParams()
    .set('month', filterObj.month)
    .set('week', filterObj.week);
    return this.httpClient.get(environment.apiUrl + '/dashboards/points/weekbyid', {params})
        .pipe(
          map (response => response),
          pluck('data'),
          catchError(this._errorHandler)
        );
  }

  createLeads(leads: any) {
    return this.httpClient.post(`${environment.apiUrl}/dashboards/points/leads`, leads)
    .pipe(
      map (response => response),
      pluck('data'),
      catchError(this._errorHandler)
    );
  }



  _errorHandler(error: Response) {
    return throwError(error || 'Server Error');
  }
}
