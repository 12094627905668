import * as AuthActions from '../actions/auth.action';

export interface AuthState {
  token: string;
  authenticated: boolean;
  user: string;
  phone: string;
  email: string;
  role: string;
  profile: string;
}

const initialState: AuthState = {
  token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null,
  authenticated: localStorage.getItem('token') ? true : false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  role: localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : null,
  email: localStorage.getItem('email') ? JSON.parse(localStorage.getItem('email')) : null,
  phone: localStorage.getItem('phone') ? JSON.parse(localStorage.getItem('phone')) : null,
  profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null,

};

export function authReducer(state = initialState, action: AuthActions.AuthActions) {
  switch (action.type) {
    case (AuthActions.SIGNIN):
      return {
        ...state,
        authenticated: true
      };
    case (AuthActions.LOGOUT):
      return {
        ...state,
        token: null,
        authenticated: false
      };
    case (AuthActions.SET_TOKEN):
      return {
        ...state,
        token: action.payload
      };

    case (AuthActions.SET_EMAIL):
      return {
        ...state,
        email: action.payload
      };

    case (AuthActions.SET_PHONE):
      return {
        ...state,
        phone: action.payload
      };
    case (AuthActions.SET_ROLE):
      return {
        ...state,
        role: action.payload
      };
    case (AuthActions.SET_USER):
      return {
        ...state,
        user: action.payload
      };
    case (AuthActions.SET_PROFILE):
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
}
