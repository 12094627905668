import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, shareReplay, tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient
  ) { }


  signIn(userObj: { username: string, password: string}) {
    return this.httpClient.post<any>(environment.apiUrl + '/auth', userObj)
              .pipe(
                shareReplay(),
                tap(response => {
                  this.saveLocalStorage(response);
                }),
                map(response => response.data),
                catchError(this.errorHandler)
              );
  }


  getExpiration() {
    const expiration = localStorage.getItem('expire');
    const expiresAt = JSON.parse(expiration);
    return expiresAt;
  }

  isTokenExpired() {
    const token = localStorage.getItem('token');
    if (token) {
      const expire = helper.isTokenExpired(token);
      return expire;
    } else {
      return false;
    }
  }


  getExpireDate() {
    const token = localStorage.getItem('token');
    if (token) {
      const expire = helper.getTokenExpirationDate(token);
      return expire;
    } else {
      return true;
    }
  }

  removAuth() {
    localStorage.clear();
  }

  errorHandler(response: any) {
    return throwError(response.error.message || 'Server Error');
  }

  saveLocalStorage(response: any) {
    localStorage.setItem('token', JSON.stringify(response.meta.token));
    localStorage.setItem('user', JSON.stringify(response.data.full_name));
    localStorage.setItem('phone', JSON.stringify(response.data.phone));
    localStorage.setItem('role', JSON.stringify(response.data.role.name));
    localStorage.setItem('email', JSON.stringify(response.data.email));
    localStorage.setItem('profile', JSON.stringify(response.data.photo_profile));

  }
}
