<div class="container-header" fxLayout="row" fxLayoutAlign="center end">
  <div
    class="menu"
    fxFlex="100%"
    fxFlex.xs="100%"
    fxFlex.sm="100%"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <div
      class="block"
      fxFlex="100%"
      fxFlex.xs="100%"
      fxFlex.sm="100%"
      fxLayout="row"
      fxLayoutAlign="space-between start"
    >
      <div
      fxFlex="20%"
      fxFlex.xs="20%"
      fxFlex.sm="20%" class="left" fxLayout="row" fxLayoutAlign="center center">
       
        
      </div>
      <div
        fxFlex="60%"
        fxFlex.xs="60%"
        fxFlex.sm="60%"
        class="center"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <span class="text" fxLayout="row" fxLayoutAlign="center center"
          >Dashboard Point</span
        >
      </div>
      <div class="right" fxLayout="row" fxLayoutAlign="end start">
        <img class="image" *ngIf="profileUrl; else noImage" [src]="profileUrl" alt="" />
      </div>
    </div>
  </div>
</div>

<ng-template #noImage>
    <img  class="image" src="assets/img_avatar.png" alt="">
</ng-template>
