import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { UpdateLeadModalComponent } from './update-lead-modal/update-lead-modal.component';
import { MenuModalComponent } from './menu-modal/menu-modal.component';
import * as fromAll from '../../reducers/allreducer.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/interface/user';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  defaultImage = '/assets/loading.gif';
  $authState: Observable<User>;
  $role: Observable<string>;
  role: string;
  
  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private store: Store<fromAll.AllState>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.$authState   = this.store.select('auth');

   }

  ngOnInit() {
    this.registerIcon('add', 'assets/icons/add.svg');
    this.$role = this.$authState.pipe(map(storeAuth => storeAuth.role ));
    this.$role.subscribe(role => this.role = role);
  }

  registerIcon(icon: string, path: string) {
    this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }

  onClickButton(link: string) {
    this.router.navigate([link]);
  }

  onUpdateLead() {
    if (this.role === 'Deal Maker') {
      const dialogRef = this.dialog.open(UpdateLeadModalComponent, {
        data: null,
        width: '380px',
        panelClass: 'no-padding-dialog'
      });
      dialogRef.afterClosed().subscribe(val => {
        console.log(val);
        if (val) {
          this.onWarning('Update lead berhasil ');
        }
      });
    } else {
      this.onWarning('Update lead is not available !');
    }

  }

  onOpenMenu() {
    const dialogRef = this.dialog.open(MenuModalComponent, {
      data: null,
      width: '380px',
      panelClass: 'no-padding-dialog'
    });
    dialogRef.afterClosed().subscribe(val => {
      console.log(val);
    });
  }

  onWarning(message: string) {
    const snackBarRef = this.snackBar.open(message, 'close', {
      duration: 3000
    });
    snackBarRef.afterDismissed().subscribe(() => {
      // console.log('The snack-bar was dismissed');
    });
  }

  onOpenProfile() {
    this.router.navigate(['profile/home']);
  }


}
