import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as fromAll from '../../reducers/allreducer.reducer';
import { User } from 'src/app/interface/user';
import { AuthService } from 'src/app/auth/auth.service';
import * as AuthActions from 'src/app/actions/auth.action';
import { Route, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  $authState: Observable<User>;
  $appState: Observable<{title: string, loading: boolean }>;
  $title: Observable<string>;
  $user: Observable<string>;
  $phone: Observable<string>;
  $role: Observable<string>;
  $email: Observable<string>;
  $isLoading: Observable<boolean>;


  constructor(
    private store: Store<fromAll.AllState>,
    private authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.$appState   = this.store.select('app');
    this.$authState   = this.store.select('auth');

   }

  ngOnInit() {
    this.$title = this.$appState.pipe(map(storeApp => storeApp.title ));
    this.$isLoading = this.$appState.pipe(map(storeApp => storeApp.loading ));
    this.$user = this.$authState.pipe(map(storeAuth => storeAuth.user ));
    this.$phone = this.$authState.pipe(map(storeAuth => storeAuth.phone ));
    this.$role = this.$authState.pipe(map(storeAuth => storeAuth.role ));
    this.$email = this.$authState.pipe(map(storeAuth => storeAuth.email ));

    this.registerIcon('notification', 'assets/icons/notification.svg');

  }

  onNavigate(path: string) {
    this.router.navigate([path]);
  }

  registerIcon(icon: string, path: string) {
    this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }

  onLogOut() {
    this.store.dispatch( new AuthActions.Logout());
    this.authService.removAuth();
    this.router.navigate(['/signin']);
  }

}
