import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError, pluck } from 'rxjs/operators';
import { ProfileService } from 'src/app/services/profile.service';

@Injectable()
export class UserResolver implements Resolve<any>{

  constructor(
    private httpClient: HttpClient,
    private profilerService: ProfileService) {
  }
  resolve() {
    const userId = this.profilerService.getUserId();
    return this.httpClient.get(environment.apiUrl + '/users/' + userId).pipe(
      map (response => response),
      pluck('data')
    );
  }

}