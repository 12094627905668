import * as AppActions from './../actions/app.action';

export interface AppState {
    title: string;
    loading: boolean;
    backUrl: string;
}


const initialState: AppState = {
    title: '',
    loading: false,
    backUrl: null
};

export function appReducer(
    state: AppState = initialState,
    action: AppActions.AppActions
) {
    switch (action.type) {
        case AppActions.SET_TITLE:
            return { ...state, title: action.payload };
        case AppActions.SET_LOADING:
            return { ...state, loading: action.payload };
        case AppActions.SET_BACK_URL:
            return { ...state, backUrl: action.payload };
        default:
            return state;
    }

}
