<div class="container" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <app-header-home></app-header-home>
        <div class="block" fxLayout="row" fxLayoutAlign="center center">
            <div class="board-point" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayoutAlign="center center">
                <div class="points"  fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                        <div class="point" fxFlex="40%" fxFlex.xs="60%" fxFlex.sm="60%" fxLayout="column" fxLayoutAlign="center center">
                            <div class="block" fxFlex="100%" fxFlex.xs="100%" fxLayout="column" fxLayoutAlign="center center" fxFlex.sm="100%" >
                                <span class="field">Point Hari ini</span>
                                <span class="value">{{ totalPointToday | roundTwoDigit }}</span>
                            </div>
                        </div>
                                
                </div>
            </div>
        </div>
        <div class="block" fxLayout="row" fxLayoutAlign="center center">
            <div class="elemens" fxFlex="90%" fxFlex.xs="97%" fxFlex.sm="95%">
                <div class="elemen" *ngFor="let unit of units;let i = index;">
                    <div class="accordion" fxLayout="row" fxLayoutAlign="start space-between">
                            <div class="left" fxFlex="70%" fxFlex.xs="70%" fxFlex.sm="70%" fxLayout="column" fxLayoutAlign="start start">
                                <span class="field">{{ unit.title }}</span>
                                <h3>{{ unit?.point | roundTwoDigit }}</h3>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" class="right" fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%">
                                <div class="icon-top" (click)="toggleAccordian($event, i)">
                                    <i *ngIf="unit.isActive" fxLayout="row" fxLayoutAlign="center center" class="material-icons icon">keyboard_arrow_up</i>
                                    <i *ngIf="!unit.isActive" fxLayout="row" fxLayoutAlign="center center" class="material-icons icon">keyboard_arrow_down</i>

                                </div>
                            </div>
                    </div>
                    <div class="panel" hide="!item.isActive">
                            <div class="block" fxLayout="row" fxLayoutAlign="center center">
                                <div class="table" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="column" fxLayoutAlign="start start">
                                    <div class="head" fxLayout="row" fxLayoutAlign="start start">
                                        <span class="elemen" class="name" fxFlex="25%" fxFlex.xs="25%" fxFlex.sm="25%">
                                            {{ unit.subtitle }}
                                        </span>
                                        <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                Month
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                Today
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                Leads
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                Deal
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                Ratio
                                            </span>
                                    </div>
                                    <div *ngFor="let user of unit.users;let j = index;" class="content" fxLayout="row" fxLayoutAlign="start start">
                                            <span class="elemen" class="name" fxFlex="25%" fxFlex.xs="25%" fxFlex.sm="25%">
                                                {{ user.full_name }}
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                {{ user.point_month | roundTwoDigit }}
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                {{ user.total_point | roundTwoDigit }}
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                {{ user.leads }}
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                {{ user.daily_deal }}
                                            </span>
                                            <span fxLayout="row" fxLayoutAlign="center center" class="field" fxFlex="15%" fxFlex.xs="15%" fxFlex.sm="15%">
                                                {{ user.adsRatio }}%
                                            </span>
                                        </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>        
        </div>

</div>


