import { Action } from '@ngrx/store';
import { environment } from './../../environments/environment';
export const SET_TITLE = 'SET_TITLE';
export const SET_LOADING = 'SET_LOADING';
export const SET_BACK_URL = 'SET_BACK_URL';

export class SetTitle implements Action {
    readonly type = SET_TITLE;
    constructor(public payload: string) {}
}

export class Setloading implements Action {
    readonly type = SET_LOADING;
    constructor(public payload: boolean) {
    }
}

export class SetBackUrl implements Action {
    readonly type = SET_BACK_URL;
    constructor(public payload: string) {
    }
}





export type AppActions = SetTitle | Setloading | SetBackUrl;
